import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'


import Formularz from '../../static/pliki/15112018_WARUNKI_CYNKOWANIA.pdf'

import "../components/layout.css"

export const OcynkowniaPageTemplate = ({ intro, gallery }) => (
  <div>
    <div>
      <div></div>
    </div>
    <section className="section section--gradient antyPadding">
      <div className="container">
        <div className="section AboutP ocynkowniaP antyPadding">
          <div className="columns">
            <div className="column is-10 is-offset-1 StoPro">
              <div className="content">
                <div className="columns">
                  <div className="column is-12">
                  </div>
                </div>
                <div className="elooo alternatywka eluwina"><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                <div className="cynkowanieWirowe">
                  <Features
                    gridItems={intro.blurbs}
                    key={intro.toString()}
                    href=""
                    />
                    </div>

                  <div className="descriptionTEXT">



                    <p className="descriptionCSS ocynkowniaTEXT">Miło nam poinformować, że na przełomie 2016-2017 roku w ramach działań rozwojowych firmy EKOCHRON otworzyliśmy najnowocześniejszą w regionie cynkownię ogniową. Budowana od podstaw nowa hala wraz z cynkownią, swoją pełną moc przerobową uzyskała w marcu 2017r.<br></br><br></br>

                      Cynkownia dysponuje wanną o wymiarach 2000 x 900 x 1500 o wydajności 24t/24h stosując metodę cynkowania na zawieszkach.<br></br><br></br>

                      Nasze powłoki cynkowe są wykonywane zgodnie z normą PN-EN 1461. Z uwagi na specyfikę procesu grubość, a tym samym skuteczność antykorozyjna warstwy cynku jest dużo większa niż cynku galwanicznego i w zależności od grubości pokrywanego materiału wynosi co najmniej 35 µm. Taka warstwa cynku zapewnia ochronę antykorozyjną stali nawet na kilkadziesiąt lat.<br></br><br></br>

                      Dysponując wydziałem produkcyjnym, cynkownią i lakiernią proszkową firma EKOCHRON może kompleksowo realizować różnego rodzaju zlecenia zapewniając najwyższej jakości usługi i wyroby. Czego potwierdzeniem, może być wdrożony i stosowany od lat System Zarządzania Jakością PN-EN ISO-9001.<br></br><br></br>

Firma EKOCHRON znajduje się na terenie RSP Promień w Ogrodzonej. Zatrudnia około 70 pracowników ze Śląska Cieszyńskiego będąc jednym z największych pracodawców w gminie Dębowiec. <br></br><br></br><b className='Kontakt_Cynkownia'>Nr kontaktowy do spraw związanych z cynkownią: 503 776 691</b><br></br>

                      </p>
                    <a className="pobierzFormularz" target="_blank"  rel="noopener noreferrer" href={Formularz}></a>
                    <iframe title="Mapa Cynkowni Ekochron" className="mapaCynkownia" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.9435698872253!2d18.720865315893242!3d49.768318444159505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4714031ed374c143%3A0x5674b1f88270af3d!2sEkochron!5e0!3m2!1spl!2spl!4v1579008095331!5m2!1spl!2spl"></iframe>

                  </div>
                </div>
                <div className="galeriaRIGHT galeriaRIGHTonas">

                  <div className="galeriaONAS galeriaCynkownia">
                    <Features gridItems={gallery.blurbs} />
                  </div>
                </div>
                <div className="columns artykulyProd">
                  <div className="column is-12 has-text-centered">

                  </div>
                </div>
                <div className="column is-12 wpisy">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Najnowsze wpisy:
                         </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Czytaj więcej
                           </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

OcynkowniaPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const OcynkowniaPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <OcynkowniaPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

OcynkowniaPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default OcynkowniaPage

export const pageQuery = graphql`
         query OcynkowniaPageTemplate {
           markdownRemark(
             frontmatter: { templateKey: { eq: "ocynkownia-page" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
